const server = {
    path: "/college",
    name: "college",
    component: () => import("../views/home.vue"),
    redirect: "/console/leasedInstance",
    meta: { title: "menuCollegeHead", },
    children: [
        {
            path: '/console/leasedInstance',
            name: 'leasedInstance',
            component: () => import('../views/college/leasedInstance.vue'),
            meta: {
                title: "menuCollegeLeasedInstance",
                icon: "menuCollegeLeasedInstance",
            },
        },
        {
            path: '/console/addDisk',
            name: 'AddDisk',
            component: () => import('../views/college/addDisk.vue'),
            meta: {
                title: "menuCollegeAddDisk",
                icon: "menuCollegeAddDisk",
            },
        },
        {
            path: '/console/myInstance',
            name: 'MyInstance',
            component: () => import('../views/college/myInstance.vue'),
            meta: {
                title: "menuCollegeMyInstance",
                icon: "menuCollegeMyInstance",
            },
        },

    ]
};

export default server;
