export default {
    // 测试
    listButton: '按鈕',
    // 导航栏
    navLogOut: "退出登錄",
    navLogin: "登錄",
    // 菜单列表
    menuServerHead: '晨羽POD',
    menuServerGho: 'POD鏡像',
    menuServerPodImage: '我的POD鏡像',
    menuServerPod: '我的POD實例',
    menuServerModel: '開源大模型集',
    menuServerGpu: 'Gpu 服務',
    menuServerFramer: '創作者管理',

    menuSchoolHead: '晨羽Ai學院',
    menuSchoolTeacherHome: '名師學堂',
    menuSchoolMySchool: '我的學堂',
    menuSchoolSchoolManage: '學堂管理',

    menuCollegeHead: '高校專區',
    menuCollegeLeasedInstance: '租用實例',
    menuCollegeAddDisk: '創建鏡像',
    menuCollegeMyInstance: '我的實例',

    menuModelHead: '大模型API服務',
    menuModelAPIKey: 'API Key',
    menuModelChat: 'API快捷體驗',

    menuManageHead: '管理',
    menuManageBilling: '充值',
    menuManageAccount: '賬號管理',
    menuManageFile: '文件管理',
    menuManageHashrate: '算力卡',

    menuHelpHead: '幫助',
    menuHelpContact: '聯系我們',
    menuHelpDocs: '使用文檔',
    // 镜像列表页面
    // pod服务页面
    // apiKeys页面
    apiKeys_describe: "不要與他人共享您的API密鑰，也不要在瀏覽器或其他客戶端代碼中公開它。",
    apiKeys_secret_key: "密鑰",
    apiKeys_creationNewKey: "創建新密鑰",
    apiKeys_editKey: "編輯密鑰",
    // 计费页面
    billing_recharge: '充值',
    billing_rechargeRecord: '充值記錄',
    billing_consumptionFlow: '消費流水',

    // 公用
    Loading: '加載中...',
    Added: '新增',
    DeleteAffirm: '確定刪除嗎 ?',
    Serial: "序號",
    Desc: "描述",
    Name: "名稱",
    State: "狀態",
    Created_at: "創建時間",
    Start_at: "開始時間",
    End_at: "結束時間",
    Time: "時間",
    Operate: "操作",
    Start: "開始",
    User: "用戶",
    Amount: "金額",
    Pay_time: "支付時間",
    OutTradeNo: "訂單號",
    Remark: "備註",
    Category: "類別",
};