<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>
<script>
// app.vue写在script里面  main.js写在app挂载完之后
const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
};

import zh from "ant-design-vue/es/locale/zh_CN";
import tc from "ant-design-vue/es/locale/zh_TW";
import en from "ant-design-vue/es/locale/en_US";

import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";
export default {
  components: {},
  name: "App",
  data() {
    return {};
  },
  watch: {
    $route: {
      handler: function (route) {
        let invitedCode = route.query && route.query.invitationCode;
        if (
          invitedCode &&
          !this.user &&
          !this.token &&
          route.path != "/console/share" &&
          route.path != "/console/login"
        ) {
          this.$router.push(`/console/login?invitationCode=${invitedCode}`);
        }
      },
      immediate: true, // 这样初始化的时候也会触发
      deep: true, // 它会进行深度监听
    },
  },
  props: {},
  created() {
    sessionStorage.getItem("lang")
      ? (this.$i18n.locale = sessionStorage.getItem("lang"))
      : (this.$i18n.locale = "zh");

    if (this.$i18n.locale == "zh") {
      this.changeBroadsideWidthState(230);
    } else if (this.$i18n.locale == "tc") {
      this.changeBroadsideWidthState(230);
    } else if (this.$i18n.locale == "en") {
      this.changeBroadsideWidthState(250);
    }
  },
  mounted() {
    if (this.token && this.user) {
      this.getInfo();
    }
  },
  computed: {
    locale() {
      if (this.$i18n.locale == "zh") {
        // console.log("1 zh语");
        dayjs.locale("zh-cn");
        return zh;
      } else if (this.$i18n.locale == "tc") {
        // console.log("2 tc语");
        dayjs.locale("zh-tw");
        return tc;
      } else if (this.$i18n.locale == "en") {
        // console.log("3 en语");
        dayjs.locale("en");
        return en;
      }
    },
  },
  methods: {},
};
</script>
<style lang="scss">
@import "@/utils/common.scss";

* {
  margin: 0;
  padding: 0;
}
#app {
  // 解决404等页面的高度撑开问题
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth; // 平滑滚动
}
body {
  line-height: 1;
  color: white;
  background-color: rgb(242, 245, 249);
  font-family: Source Han Sans SC, Source Han Sans SC;
}

// 修改滚动条
@media (max-width: 600px) {
  html {
    scrollbar-width: none;
  }
  html::-webkit-scrollbar {
    display: none !important;
    background: red !important;
  }
}
body {
  ::-webkit-scrollbar {
    height: 9px;
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border-style: dashed;
    border-color: transparent;
    border-width: 3px;
    background: rgba(157, 165, 183, 0.2);
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(157, 165, 183, 0.7);
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
</style>
